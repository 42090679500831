import { once } from 'lodash-es';
import { whenIdle } from '../../util/idle';
import { isTrackingAllowed, searchInsightsConsentCategories } from '../search/algolia';

/**
 * Lazily initialise the search autocomplete functionality, embedded in the
 * navigation header.
 *
 * Initialisation happens when the user interacts with the search input or the
 * first time the browser is idle, whichever happens first.
 *
 * This method is expected to be called once per page load.
 */
export const initialiseLazySearch = () => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const loadModule = once(async () => {
    const { initialiseAlgoliaAutocomplete } = await import('.');
    return { initialiseAlgoliaAutocomplete };
  });

  const init = once(async () => {
    const { initialiseAlgoliaAutocomplete } = await loadModule();

    abortController.abort();
    initialiseAlgoliaAutocomplete(
      document.querySelector('.header__search'),
      document.querySelector('.header__search-input'),
    );
  });

  // If the customer interacts with the search input, load the module and
  // initialise instantsearch.
  const input = document.querySelector('.header__search-input');
  input.addEventListener('input', () => { init(); }, { capture: true, once: true, signal });
  input.addEventListener('focus', () => { init(); }, { capture: true, once: true, signal });

  // If the browser is idle before the customer has interacted with the search
  // input, load the module and then initialise instantsearch if we're allowed
  // to enable search insights.
  whenIdle(async () => {
    await loadModule();

    if (isTrackingAllowed()) {
      init();
    }
  });

  // If the customer's consent changes and we're able to enable search insights,
  // make sure instantsearch is initialised when the browser is next idle.
  window.addEventListener(
    'OneTrustGroupsUpdated',
    (e) => {
      if (searchInsightsConsentCategories.every((category) => e.detail.includes(category))) {
        whenIdle(() => init());
      }
    },
    { signal },
  );
};
